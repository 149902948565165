<template>
  <head>
    <meta charset="utf-8">
    <meta property="og:title" content="COWCOWCOW">
    <meta property="og:description" content="11번가 아마존, 오늘의 최저가 상품">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="11번가 아마존, 오늘의 최저가 상품">
    <meta name="author" content="binsoore@gmail.com">
    <link rel="icon" href="../img/favicon.png">

    <title>11번가 아마존 최저가 핫딜</title>
    <!-- Bootstrap core CSS -->
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">

    <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>

  <body>

    <header>
      <div class="collapse bg-dark" id="navbarHeader">
        <div class="container">
          <div class="row">
            <div class="col-sm-8 col-md-7 py-4">
              <h4 class="text-white">About</h4>
              <p class="text-muted">Menu</p>
            </div>
            <div class="col-sm-4 offset-md-1 py-4">
              <h4 class="text-white">Contact</h4>
              <ul class="list-unstyled">
                <li><a href="#" class="text-white">Follow on Twitter</a></li>
                <li><a href="#" class="text-white">Like on Facebook</a></li>
                <li><a href="#" class="text-white">Email me</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar navbar-dark bg-dark box-shadow">
        <div class="container d-flex justify-content-between">
          <a href="#" class="navbar-brand d-flex align-items-center">
            <strong>cowcowcow</strong>
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
    </header>
    <main role="main">
    <section class="jumbotron text-center">
      <div class="container">
        <h3 class="jumbotron-heading">11번가 아마존, {{ getNow() }} 최저가 </h3>
        <p class="lead text-muted">매일 4시간 간격으로 갱신합니다.</p>

      </div>
    </section>
    
    <div class="album py-5 bg-light">
      <div class="container" >
        <h5 class="my-0 font-weight-normal"> Total : {{ total = Object.keys(data).length }} </h5>

          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click="prevPage">Previous</a>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link" href="#" @click="nextPage">Next</a>
              </li>
            </ul>
          </nav>

        <div v-for="(item, index) in paginatedData" :key="'main-' + index">
          
          <div class="card mb-4 box-shadow main-card">
            <div class="card-header">
              <h6 class="my-0 font-weight-normal">No. {{ IndexPos+index+1 }} / {{ total }}</h6>
            </div>
            <a :href="`https://www.11st.co.kr/products/`+item.value.ProductCode+`/share?gsreferrer=SZF6713`" target="_blank">
              <img class="card-img-top" :src="item.value.ProductInfo.BasicImage" alt="Product Image"></a>
            <div class="card-body">
              <h4 class="card-text">{{ item.value.ProductInfo.ProductName }}</h4>
              <div>최저 : {{item.value.LowPrice.toLocaleString() }} 원</div>
              <div>현재 : {{item.value.NowPrice.toLocaleString() }} 원</div>
              <div :set="Sale = { SalePer: SalePercent(item.value.NowPrice, item.value.LowPrice) }"></div> 
              
              <div :style="{color: Sale.SalePer > 10 ? 'red' : 'black', 'font-size': SaleFontSize(Sale.SalePer) }">할인율 : {{ Sale.SalePer }}  % </div>
              <br>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a :href="`https://www.11st.co.kr/products/`+item.value.ProductCode+`/share?gsreferrer=SZF6713`" target="_blank">
                  <button type="button" class="btn btn-sm btn-outline-secondary">View</button></a>
                </div>
                <small class="text-muted">Update {{getTime(item.value.Timestamp) }}</small>
              </div>
            </div>
          </div>
        </div>
        
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click="prevPage">Previous</a>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link" href="#" @click="nextPage">Next</a>
              </li>
            </ul>
          </nav>



      </div>
    </div>
    
    </main>
    <footer class="text-muted">
      <div class="container">
        <p class="float-right">
          <a href="#">Back to top</a>
        </p>
        <p><a href="mailto:binsoore@naver.com">binsoore@naver.com</a></p>
      </div>
    </footer>
  </body>
</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';

export default {
  name: 'App',
  data() {
      return {
        data: null,
        currentPage: 1, // 현재 페이지 번호
        itemsPerPage: 100, // 한 페이지에 보여줄 게시글의 개수
      };
    },

  created() {
    document.title = "11번가 아마존, 오늘 최저가 상품 찾기";
    this.get();
    },

  computed: {
    paginatedData() {
          const startIndex = (this.currentPage - 1) * this.itemsPerPage;
          const endIndex = startIndex + this.itemsPerPage;
          console.log(startIndex, endIndex)
          return this.data.slice(startIndex, endIndex);
        },
    totalPages() {
      return Math.ceil(Object.keys(this.data).length / 100);
    },
    IndexPos() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
  },

  methods: {

    changePage(page) {
          this.currentPage = page;
        },
    nextPage() {
          if (this.currentPage < this.totalPages) {
            this.currentPage += 1;
          }
        },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },        
        
    get() {
      axios.get('https://api.cowcowcow.co.kr/lowprice')
        .then((response) => {
          for (const r in response.data) {
            // 배열로 생성
            this.data = this.sortObject(response.data[r]);
            break;
          }
          // console.log(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 
    // 최저가격으로 정렬
    sortObject(obj) {
      var arr = [];
      for (var prop in obj) {
        arr.push({
            'key': prop,
            'value': obj[prop]
        });
      }
      arr.sort(function(a, b) { return  ( a.value.NowPrice / a.value.LowPrice ) - ( b.value.NowPrice / b.value.LowPrice ); });
      //arr.sort(function(a, b) { return a.value.toLowerCase().localeCompare(b.value.toLowerCase()); }); //use this to sort as strings
      return arr; // returns array
    },

    SalePercent(Now, Low) {
      return (( 1 - Now / Low) * 100).toFixed(2)
    },
    SaleFontSize(SalePer) {
      let size = Math.floor(Number(SalePer));
      if (size > 20) {
        return '48px'
      } else if (size >= 15) {
        return '36px'
      } else if (size >= 10) {
        return '24px'
      } else if (size >= 5) {
        return '18px'
      } else {
        return '12px'
      }
      
    },
    getTime(tmstamp) {
      let theDay = dayjs.unix(tmstamp).format('MM-DD HH:mm:ss') ;
      return theDay;
    },
    getNow() {
      const today = new Date();
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      return (today.getMonth()+1)+'월'+today.getDate() + '일 ';
      // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      // const dateTime = date +' '+ time;
      // this.timestamp = dateTime;
    },
  },
  components: {
  }
}
</script>
 
<style>

/* Custom CSS to position Child Card */
.main-card {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10%;
}

body {
  background-color: #f5f5f5;
}
</style>

<!-- {
  "ProductCode": "5244092215",
  "LowPrice": 21930,
  "NowPrice": 21930,
  "ProductInfo": {
    "ProductCode": "5244092215",
    "ProductName": "Jarrow Formulas 자로우 도피러스 식물성 캡슐 60정",
    "ProductPrice": {
      "Price": "23,760원",
      "LowestPrice": "21,930원"
    },
    "BasicImage": "https://cdn.011st.com/11dims/strip/false/11src/asin/B003B6NASE/B.jpg?1693545651311",
    "AddImage1": "https://cdn.011st.com/11dims/strip/false/11src/asin/B003B6NASE/A1.jpg?1693545651309",
    "AddImage2": "https://cdn.011st.com/11dims/strip/false/11src/asin/B003B6NASE/A2.jpg?1693545651315",
    "AddImage3": "https://cdn.011st.com/11dims/strip/false/11src/asin/B003B6NASE/A3.jpg?1693545651311",
    "ImageL300": "https://cdn.011st.com/11dims/strip/false/11src/asin/B003B6NASE/B.jpg?1693545651311",
    "Point": "0",
    "Chip": "0",
    "Installment": null,
    "ShipFee": "무료배송",
    "SellSatisfaction": "94",
    "SellGrade": "4"
    "Timestamp": "1634172000"
  }
}, -->


