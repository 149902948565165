import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag"

createApp(App).mount('#app')


App.use(VueGtag, {
    config: { id: "G-BJ4TWKB9QW" }
    });
